<template>
  <div class="about-wrapper">
    <h3 class="about-title">Layanan Pelanggan</h3>
    <hr class="line-content mt-4">
    <div class="mt-5 d-flex flex-column">
      <div class="content">(021) 5729000</div>
      <div class="content-btn __blue mt-3">Telepon Layanan Pelanggan</div>
    </div>
    <div class="mt-5 d-flex flex-column">
      <div class="content">cs@sarungindonesia.co.id</div>
      <div class="content-btn __red mt-3"><a href="mailto:cs@sarungindonesia.co.id" target="_blank">Email Layanan
          Pelanggan</a></div>
    </div>
  </div>
</template>
<script>

export default {
  name: 'component.customer-service',
}
</script>

<style scoped lang='scss'>
.about-wrapper {
  width: 100%;
  margin: 0 auto;
}

.about-wrapper .about-title {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  line-height: 40px;
  color: var(--biz-primary-100);

  @media (max-width:576px) {
    font-size: 18px;
    line-height: 20px;
  }
}

.content {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;

  @media (max-width:576px) {
    font-size: 12px;
    line-height: 20px;
  }
}

.content-btn {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  cursor: pointer;

  @media (max-width:576px) {
    font-size: 12px;
    line-height: 20px;
  }
}

.__blue {
  color: #43A6E4;
}

.__blue:hover {
  color: #26a6f5;
}

.__red, a {
  color: var(--biz-tertiary-100);
}

.__red:hover, a:hover {
  color: var(--biz-tertiary-80);
}
</style>
