<template>
  <div class="about-wrapper-views">
    <CustomerService />
  </div>
</template>
<script>
import CustomerService from '@/components/CustomerService/index.vue'
export default {
  components: { CustomerService },
  name: 'customer-service',
}
</script>
<style scoped lang="scss">
.about-wrapper-views {
  width: 80%;
  margin: 0 auto;

  @media (max-width: 567px) {
    width: 95%;
  }
}
</style>
